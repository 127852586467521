.announcement
  @apply bg-pink text-black text-center p-3  transition font-median
  @apply hover:bg-pink-600

.close
  @apply absolute top-0 right-0 p-3 opacity-0 transition font-bold

  .announcement:hover &
    @apply opacity-100

  &:hover
    opacity: 100 !important
