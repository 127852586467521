.tweet
  @apply relative m-auto max-w-[400px] rounded-[10px] md:max-w-[360px]
  @apply pt-[42px] pb-[32px] pl-[28px] pr-[38px]
  @apply md:pt-[28px] md:pb-[18px] md:pl-[15px] md:pr-[15px]
  @apply lg:pt-[44px] lg:pb-[30px] lg:pl-[30px] lg:pr-[30px]

  &.hoverable
    @apply transition lg:transform scale-100

    &:hover
     @apply lg:scale-110

  &.green
    &, .avatar
      @apply bg-green-500

    .text, a, a:hover
      @apply text-black

      &::selection,
      *::selection
        @apply bg-green-600

  &.pink
    &, .avatar
      @apply bg-pink-500

    .text, a, a:hover
      @apply text-black

  &.blue
    &, .avatar
      @apply bg-blue-500

    .text, a, a:hover
      @apply text-white

  .content
    @apply font-neutral break-words mb-[12px] md:mb-[14px]

  .name
    @apply font-neutral

  .author
    @apply absolute
    @apply h-[50px] w-[50px] top-[-26px] left-[36px]
    @apply md:h-[36px] md:w-[36px] md:top-[-18px] md:left-[15px]
    @apply lg:h-[50px] lg:w-[50px] lg:top-[-25px] lg:left-[30px]

  .avatar
    @apply rounded-full
