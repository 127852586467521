.wrapper {
  @apply flex flex-col content-center justify-center flex-wrap;
  margin-top: 110px;
  margin-bottom: 100px;
}

.textSection {
  @apply flex-row text-center justify-center flex-wrap;
  margin: 0 auto;
  max-width: 350px;
}

.subheading {
  font-weight: 800;
  font-size: 30px;
}

.companiesBody {
  font-size: 18px;
  margin-top: 13px;
}

.iconSection {
  @apply grid grid-cols-3 gap-y-8 place-items-center;
  max-height: 240px;
  margin: 40px 20px 0 20px;
}

/* To make sure we only display 6 icons on mobile */
/* Does not render anything from 7th element*/
.iconSection :nth-child(n + 7) {
  display: none;
}

@screen sm {
  .textSection {
    max-width: 540px;
  }

  .iconSection {
    @apply flex justify-between;
    min-width: 600px;
  }

  .iconSection :nth-child(n + 7) {
    display: flex;
  }
}

@screen xl {
  .iconSection {
    min-width: 1060px;
  }
}
