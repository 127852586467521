.community
  @apply bg-blue-500 flex items-center
  @apply py-[64px] md:py-[90px] lg:py-[120px]
  @apply mt-[65px]

  .shape
    @apply select-none

  .heading
    @apply font-neutral text-white text-center mb-[20px]
    @apply md:text-left

  .buttons
    @apply flex flex-col mb-[50px] md:mb-[40px] xl:mb-[64px] xl:flex-row

    & > * + *
      @apply mt-[20px] md:mt-[15px] xl:mt-0 xl:ml-[24px]

  .socialButtons
    @apply flex justify-between

    & > *
      @apply w-1/2

    & > * + *
      @apply ml-[20px] md:ml-[12px] lg:ml-[24px]
