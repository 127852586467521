.iconWrapper {
  @apply rounded-full bg-grey-100 flex items-center;
  height: 60px;
  width: 60px;
}

.iconWrapper > * {
  @apply rounded-full flex;
}

/* Required for Safari to display SVGs on large screen */
.iconWrapper > div * {
  width: 100%;
}

/* icon svgs */
.amazon {
  width: 36%;
  margin: 36% 32% 32% 32%;
}

.apple {
  width: 23%;
  margin: 34% 38% 36% 39%;
}

.facebook {
  width: 30%;
  margin: 35%;
}

.google {
  width: 28%;
  margin: 36%;
}

.twitter {
  width: 28%;
  margin: 39% 36%;
}

.linkedin {
  width: 26%;
  margin: 37%;
}

.spotify {
  width: 30%;
  margin: 35%;
}

:global(.dark) .iconWrapper {
  @apply bg-grey-800;
}

:global(.dark) .iconWrapper > div * {
  @apply text-white;
}

@screen xl {
  .iconWrapper {
    height: 100px;
    width: 100px;
  }
}
