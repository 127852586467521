.subheading {
  @apply text-center;

  padding-bottom: 65px;
  margin: 0 15px;
}

.buttonGroup {
  margin-top: 95px;
  margin-bottom: 30px;
}

@screen sm {
  .subheading {
    padding-bottom: 110px;
  }
}

@screen md {
  .buttonGroup {
    padding-bottom: 40px;
  }
}

@screen xl {
  .subheading {
    padding-top: 70px;
    padding-bottom: 140px;
  }

  .buttonGroup {
    padding-bottom: 90px;
  }
}
